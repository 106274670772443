<template>
  <div class="relative pt-20 pb-24 xl:pt-32 xl:pb-36 CubeCarousel">
    <!-- <EJPopup :open="!!currentArtwork" @update:open="handleOpenChange" innerClass="w-[calc(100vw-30px)] h-[calc(var(--ih)*100-30px)]">
      <CubesDemoIframe v-if="currentArtwork" :currentArtwork="currentArtwork" />
    </EJPopup> -->

    <div class="absolute top-40 left-0 bg-lime-300 bg-gradient-to-b from-[#1B1B1B] to-black h-[250px] w-full"></div>
    <div
      class="absolute z-20 top-[calc(50%-28px)] -translate-y-1/2 justify-between p-4 md:p-8 lg:p-14 2xl:p-20 touch-none">
      <button @click="artworkCarousel?.prev"
        class="bg-center h-[84px] w-[50px] touch-none group">
        <img class="block group-hover:hidden" :src="ArrowLeft.src" />
        <img class="group-hover:block hidden" :src="ArrowLeftFill.src" />
      </button>
    </div>

    <div
      class="absolute z-20 right-0 top-[calc(50%-28px)] -translate-y-1/2 justify-between p-4 md:p-8 lg:p-14 2xl:p-20 touch-none">
      <button @click="artworkCarousel?.next"
        class="bg-center h-[84px] w-[50px] touch-none group">
        <img class="block group-hover:hidden" :src="ArrowRight.src" />
        <img class="group-hover:block hidden" :src="ArrowRightFill.src" />
      </button>
    </div>

    <Carousel ref="artworkCarousel" snap-align="start" :wrapAround="true" class="w-[90%] mx-auto overflow-visible"
      :breakpoints="breakpoints" :items-to-show="1" :touch-drag="true" :mouse-drag="false" @slide-start.once="preloadLazyImages">
      <Slide v-for="(cube, i) in cubes" :key="cube.id" class="flex flex-col text-white justify-start h-fit">
        <a :href="`https://eyejack.xyz/collections/KT1H6jXeZppUf6kVp1JxBdFoCSjHb4jJZC6b/${cube.id}`" >
        <div class="m-4 cursor-pointer group" >
          <LazyImage 
            :ref="node => lazyImageEls[i] = node"
            :src="`${cube.cubeThumbnail}-50.webp`"
            :src-placeholder="placeholderImg"
            class="border rounded-md border-[#ffffff33] group-hover:scale-[1.02] transition-all group-hover:brightness-125"
            width="100%"
            height="auto"
          />
          <div class="flex flex-col items-start mt-4">
            <p class="text-left ejx-text">
              Artist: <span class="text-[#B8B8B8] group-hover:text-gray-200">{{ cube.artist }}</span>
            </p>
            <p class="text-left ejx-text">
              Name: <span class="text-[#B8B8B8] group-hover:text-gray-200">{{ cube.cubeName }}</span>
            </p>
          </div>
        </div>
      </a>
      </Slide>
    </Carousel>
  </div>
</template>

<script setup lang="ts">
import { type ComponentPublicInstance, onMounted, ref, shallowRef } from 'vue';
import LazyImage from "./generics/LazyImage";
import { Carousel, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import EJPopup from './generics/EJPopup.vue';
import CubesDemoIframe from './generics/CubesDemoIframe.vue';
import { cubes, type Cube } from '../data';

import ArrowLeft from '../assets/arrow-left.png';
import ArrowLeftFill from '../assets/arrow-left-fill.png';
import ArrowRight from '../assets/arrow-right.png';
import ArrowRightFill from '../assets/arrow-right-fill.png';
import { useEventListener } from '@vueuse/core';

defineProps({
  placeholderImg: {
    type: String,
    required: true,
  }
})

// Switching current artwork, loading the iframe
const currentArtwork = ref<Cube | undefined>();
const handleOpenChange = (isOpen: boolean) => {
  if (!isOpen) {
    history.pushState("", document.title, window.location.pathname + window.location.search);
    currentArtwork.value = undefined;
  }
}

const handleShowArtwork = (hash: string) => {
  window.location.hash = hash;
} 

// Will load the artwork defined by the window hash
onMounted(() => {
  const showArtworkFromHash = () => {
    if (window.location.hash) {
      const artworkSlug = window.location.hash.replace('#', '');
      const toActivate = cubes.find(cube => cube.cubeNameSlug === artworkSlug);
      if (toActivate) {
        currentArtwork.value = toActivate
        return;
      }
    }
    currentArtwork.value = undefined;
  }
  useEventListener(window, 'hashchange', showArtworkFromHash); 
  showArtworkFromHash();
})

// Carousel config
const artworkCarousel = ref<typeof Carousel|null>(null);
artworkCarousel.value?.next
const breakpoints = {
  400: {
    itemsToShow: 1
  },
  525: {
    itemsToShow: 2
  },
  768: {
    itemsToShow: 3
  },
  1024: {
    itemsToShow: 4
  }
}

// As soon as the carousel is interacted with, we preload all of the images.
const lazyImageEls = shallowRef([] as unknown as (ComponentPublicInstance<typeof LazyImage>|undefined | null)[]);
let hasPreload = false;
const preloadLazyImages = () => {
  if (!hasPreload) {
    hasPreload = true;
    lazyImageEls.value.forEach(node => {
      if (node && node.preload) node.preload();
    })
  }
}
</script>
