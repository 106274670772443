export interface Cube {
  artist: string,
  cubeName: string,
  /** @description Cube name for use in url hash */
  cubeNameSlug: string,
  cubeThumbnail: string,
  artworkId: string,
  id: number,
  minted?: boolean;
}

export const cubes: Cube[] = [
  // {
  //   artist: 'MLNDR',
  //   cubeName: 'Virtual Cortex',
  //   cubeNameSlug: 'virtual-cortex',
  //   cubeThumbnail: '/cubes/luis-nunez',
  //   artworkId: '15-luis-nunez',
  //   id: 1
  // },
  // {
  //   artist: 'mobotato',
  //   cubeName: 'mobo // CADE',
  //   cubeNameSlug: 'mobo-cade',
  //   cubeThumbnail: '/cubes/mobotato',
  //   artworkId: '16-mobotato',
  //   id: 2
  // },
  // {
  //   artist: 'Jake Gumbleton',
  //   cubeName: 'Uncertain Journeys',
  //   cubeNameSlug: 'uncertain-journeys',
  //   cubeThumbnail: '/cubes/jake-gumbleton',
  //   artworkId: '20-jake-gumbleton',
  //   id: 3
  // },
  {
    artist: 'Laurence Fuller, Vincent D’Onofrio, Sutu',
    cubeName: 'Sparrow',
    cubeNameSlug: 'sparrow',
    cubeThumbnail: '/cubes/sparrow',
    artworkId: '26-sparrow',
    id: 11,
    minted: true
  },
  {
    artist: '1mpo$ter',
    cubeName: 'Wolfhead',
    cubeNameSlug: 'wolfhead',
    cubeThumbnail: '/cubes/wolfhead',
    artworkId: '22-wolfhead',
    id: 16,
    minted: true
  },
 
  // {
  //   artist: 'The Bad Lament',
  //   cubeName: 'Ocean Fossils',
  //   cubeNameSlug: 'ocean-fossils',
  //   cubeThumbnail: '/cubes/bad_lament',
  //   artworkId: '23-bad-lament',
  //   id: 4
  // },
  {
    artist: 'Helena Papageorgiou',
    cubeName: 'Chroma Space',
    cubeNameSlug: 'chroma-space',
    cubeThumbnail: '/cubes/chroma-space',
    artworkId: '28-helena-pap',
    id: 14,
    minted: true,
  },
  // {
  //   artist: 'Heather Dunaway Smith',
  //   cubeName: 'Crossroads',
  //   cubeNameSlug: 'crossroads',
  //   cubeThumbnail: '/cubes/hds',
  //   artworkId: '25-hds',
  //   id: 5
  // },
  // {
  //   artist: 'Pixelpuncha',
  //   cubeName: 'Infinite Struggle Time',
  //   cubeNameSlug: 'pixel',
  //   cubeThumbnail: '/cubes/pixel-puncha',
  //   artworkId: '33-pixel-puncha',
  //   id: 6
  // },
  // {
  //   artist: 'Bzor',
  //   cubeName: 'Entity Sync',
  //   cubeNameSlug: 'entity-sync',
  //   cubeThumbnail: '/cubes/bzor',
  //   artworkId: '17-bzor',
  //   id: 7
  // },
  // {
  //   artist: 'Lukasz Karluk',
  //   cubeName: 'Void Cube',
  //   cubeNameSlug: 'void-cube',
  //   cubeThumbnail: '/cubes/lukasz-karluk',
  //   artworkId: '14-lk',
  //   id: 8
  // },
  // {
  //   artist: 'Sutu',
  //   cubeName: 'Quantum Runner',
  //   cubeNameSlug: 'quantum-runner',
  //   cubeThumbnail: '/cubes/sutu',
  //   artworkId: '12-sutu',
  //   id: 9
  // },
  {
    artist: 'Somfay',
    cubeName: 'Radioactive',
    cubeNameSlug: 'radioactive',
    cubeThumbnail: '/cubes/sutu-radioactive',
    artworkId: '',
    id: 12,
    minted: true
  },
  {
    artist: 'Sutu',
    cubeName: 'Calibration Series: Synthia___1',
    cubeNameSlug: 'calibration-series-synthia-1',
    cubeThumbnail: '/cubes/sutu-calibration-series-synthia-1',
    artworkId: '',
    id: 10,
    minted: true
  },
  {
    artist: 'PANDAKERO',
    cubeName: 'Together',
    cubeNameSlug: 'together',
    cubeThumbnail: '/cubes/pandakero',
    artworkId: '24-pandakero',
    id: 13,
    minted: true,
  }
]
